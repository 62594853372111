import React from 'react';
import Box from './Box';
import Text from './Text';
import { mobileOrDesktop } from './ThemeProvider/theme'

const Title = ({ children, innerRef, ...props }) => {
  return (
    <Box
      display="inline-block"
      borderBottom="3px dotted"
      borderColor="primary"
      fontSize={mobileOrDesktop('1.75em', '2.75em')}
      minWidth={mobileOrDesktop('12em', 'unset')}
      ref={innerRef}
      {...props}
    >
      <Text.h1
        fontSize="1em"
        lineHeight="1.25"
        letterSpacing="0.125em"
        fontFamily="Noto Serif TC"
      >
        {children}
      </Text.h1>
    </Box>
  );
};

export default Title;
