import React from 'react';
import range from 'lodash/range';

import SVG from '../SVG'

const pos = [465, 105]
const diff = pos[1] - pos[0]

const RadarTick = ({ scales, min, max, format }) => {
  const increment = (max - min) / scales;
  return (
    <SVG position="absolute" top="0" left="0" right="0" bottom="0" viewBox="0 0 894 894">
      <g transform="rotate(-30, 447, 447)">
        {range(scales).map((i) => {
          const n = min + increment * i;
          return (
            <text
              key={i}
              transform={`translate(454.6 ${pos[0] + diff * i / scales})`}
              fontSize="17"
            >{format ? format(n) : n}</text>
          );
        })}
      </g>
    </SVG>
  );
};

export default RadarTick;
