import React from 'react';

// import VerticalCenter from '../components/VerticalCenter'
import Flex from '../components/Flex'
import Box from '../components/Box'
import theme, { mobileOrDesktop }  from '../components/ThemeProvider/theme'

import Layout from './Layout'

const Page = ({ left, right }) => (
  <Layout>
    {(isCenter) => (
      <Box>
        <Flex
          flexWrap="wrap"
          pt={mobileOrDesktop('1em', 0)}
          height="100%"
          alignItems={isCenter && 'center'}
          mt={isCenter && theme.headerHeight.map((h) => h ? `calc(-${h} / 2)` : null)}
        >
          <Box
            width={mobileOrDesktop(1, 4 / 10)}
            pl={['1em', null, null, '5vw', '2.5vw', '7.5vw']}
            pr={mobileOrDesktop('1em', '3vw')}
            py={isCenter ? 0 : '1em'}
          >
            {left}
          </Box>
          <Box
            width={mobileOrDesktop(1, 6 / 10)}
            px={['1em', null, null, '5vw', '3.5vw', '7.5vw']}
            pb={!isCenter && mobileOrDesktop('2em', 0)}
          >
            {right}
          </Box>
        </Flex>
      </Box>
    )}
  </Layout>
)

export default (Page);
