import React, { PureComponent } from 'react';
import { withContentRect } from 'react-measure';
import styled from 'styled-components';
import range from 'lodash/range'
import mapValues from 'lodash/mapValues'
import round from 'lodash/round'
import set from 'lodash/set'
import smoothing from 'svg-radar-chart/smoothing'
import FadeIn from 'animate-css-styled-components/lib/Fade/In'

import RadarChart from '../../vendor/react-svg-radar';

import RadarTick from './RadarTick';
import Box from '../Box';
import BackgroundImage from '../BackgroundImage'

import radarBase from './radar-base.svg'

const StyledBox = styled(BackgroundImage)`
  circle.scale {
    fill: none;
    stroke: #f0f0f0;
    stroke-width: 3px;
    stroke-dasharray: 3,6;
  }

  circle.scale:nth-of-type(1) {
    stroke: none;
  }


  polyline.axis {
    stroke: none;
  }
`;

const captions = range(1, 13).reduce((cap, m) => set(cap, m, m), {})


class Radar extends PureComponent {
  componentDidMount() {
    this.props.measure();
  }

  render() {
    const {
      data,
      size,
      measure,
      measureRef,
      contentRect,
      scales,
      min,
      max,
      format,
      transition,
      ...props
    } = this.props;
    const { width } = contentRect.bounds;
    return (
      <StyledBox is={FadeIn} ref={measureRef} width="100%" ratio={1} src={radarBase} {...props}>
      {width && (
        <div>
          <Box position="absolute" top="50%" left="50%" transform="translate(-50%,-50%)">
            {max - min > 0 && (
              <div>
                <RadarTick min={min} max={max} scales={scales} format={format} />
                <RadarChart
                  data={data.map((d) => ({ ...d, data: mapValues(d.data, v => v / (max - min)) }))}
                  size={width}
                  captions={captions}
                  options={{
                    axes: false,
                    captions: false,
                    scales,
                    smoothing: smoothing(0),
                    transition,
                  }}
                />
              </div>
            )}
          </Box>
        </div>
      )}
    </StyledBox>
    );
  }
}

Radar.defaultProps = {
  scales: 7,
  min: 0,
  max: 1,
  format: (n) => round(n, 1),
  transition: true,
}

export default withContentRect('bounds')(Radar);
