import React, { PureComponent } from 'react';
import { withContentRect } from 'react-measure'

import Box from './Box';
import Text from './Text';
import Title from './Title';
import { mobileOrDesktop } from './ThemeProvider/theme'


class PageStart extends PureComponent {
  componentDidMount() {
    this.props.measure();
  }

  render() {
    const {
      firstTitle,
      secondTitle,
      descrip,
      subDescrip,
      measure,
      measureRef,
      contentRect,
      minWidth,
      ...props
    } = this.props;

    return (
      <Box textAlign={mobileOrDesktop('center', 'unset')} {...props}>
        <Title innerRef={measureRef}>{firstTitle}<br />{secondTitle}</Title>
        <Box
          pt={mobileOrDesktop('1em', '2em')}
          width={contentRect.bounds.width}
          minWidth={minWidth}
          mx={mobileOrDesktop('auto', 'unset')}
          textAlign="left"
        >
          <Text lineHeight="1.5" fontSize="1.2em">{descrip}</Text>
          {subDescrip && (<Text my="1em" whiteSpace="pre-wrap">{subDescrip}</Text>)}
        </Box>
      </Box>
    );
  }
}

export default withContentRect('bounds')(PageStart);
